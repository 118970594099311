import React, { memo } from 'react';
import { PromoBannerEllipse } from '@/components/landing/Promotions/components/PromoBanner/PromoBannerEllipse';
import { cn } from '@/lib';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import styles from './PromoBannerDecorationsDeprecated.module.scss';

interface Props {
  DecorIcon: FCImage;

  ellipseColorLeft: string;
  ellipseColorRight: string;
}

export const PromoBannerDecorationsDeprecated = memo<Props>((props) => {
  const {
    DecorIcon,
    ellipseColorLeft,
    ellipseColorRight,
  } = props;

  return (
    <>
      <PromoBannerEllipse
        color={ellipseColorLeft}
        className={cn(styles.decoration, styles.leftEllipse)}
      />

      <PromoBannerEllipse
        color={ellipseColorRight}
        className={cn(styles.decoration, styles.rightEllipse)}
      />

      <div className={styles.decorationsIcons}>
        <div className={cn(styles.decoration, styles.decorIcon)}>
          <DecorIcon
            size={24}
            className={styles.icon}
          />
        </div>

        <div className={cn(styles.decoration, styles.decorIcon)}>
          <DecorIcon
            size={16}
          />
        </div>

        <div className={cn(styles.decoration, styles.decorIcon)}>
          <DecorIcon
            size={32}
            className={styles.icon}
          />
        </div>

        <div className={cn(styles.decoration, styles.decorIcon)}>
          <DecorIcon
            size={32}
            className={styles.icon}
          />
        </div>

        <div className={cn(styles.decoration, styles.decorIcon)}>
          <DecorIcon
            size={16}
          />
        </div>

        <div className={cn(styles.decoration, styles.decorIcon)}>
          <DecorIcon
            size={24}
            className={styles.icon}
          />
        </div>
      </div>
    </>
  );
});
