import React, {
  memo, useCallback, useEffect,
} from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { Button, ButtonMode } from '@/components/ui/Button';
import { IconArrowRight } from '@/components/ui/icons/IconArrowRight';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { analyticsSDK } from '@/controllers/analytics';
import { PromoBannerDecorationsDeprecated } from '@/components/landing/Promotions/components/PromoBanner/PromoBannerDecorationsDeprecated';
import { CountdownPromoTimerDeprecated } from '@/components/landing/Promotions/components/PromoTimer/CountdownPromoTimerDeprecated';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import { PromoBannerBaseProps } from '@/components/landing/Promotions/components/PromoBanner/PromoBanner.typedefs';
import styles from './PromoBannerDeprecated.module.scss';

type Props = PromoBannerBaseProps;

/**
 * Banner base component that is used for different promotions.
 * F.e., Brazil Carnival, Black Friday, etc.
 * */
export const PromoBannerDeprecated = memo<Props>((props) => {
  const ctaContext = useCtaContext();

  const {
    source,
    ellipseColorLeft,
    ellipseColorRight,
    buttonText,
    DecorIcon,
    TitleIcon,
    tagText,
    timerEndAt,
    href = ctaContext.buildHref({ source }),
    shouldShowMobileTitleIcon = false,
  } = props;

  const handleButtonClick = useCallback(() => {
    analyticsSDK.landing.sendPromoBannerButtonClickedEvent({
      source,
    });
  }, [source]);

  useEffect(() => {
    const className = 'with-promo-banner';

    document.documentElement.classList.add(className);

    return () => {
      document.documentElement.classList.remove(className);
    };
  }, []);

  return (
    <AnchorElement
      href={href}
      className={styles.container}
      onClick={handleButtonClick}
    >
      <div className={styles.content}>
        {shouldShowMobileTitleIcon && (
          <div className={styles.iconWrapperSmall}>
            <TitleIcon />
          </div>
        )}

        <div className={styles.tags}>
          <div className={cn(typography.landingH6, styles.tag)}>
            <div className={styles.iconWrapper}>
              <TitleIcon className={styles.icon} />
            </div>

            <p
              className='flex-container'
              dangerouslySetInnerHTML={{ __html: tagText }}
            />
          </div>

          <CountdownPromoTimerDeprecated
            className={styles.timer}
            timerEndAt={timerEndAt}
          />
        </div>

        <Button
          className={cn(styles.button, styles.desktopButton)}
          mode={ButtonMode.Secondary}
          text={buttonText}
        />

        <Button
          className={cn(styles.button, styles.mobileButton)}
          mode={ButtonMode.TransparentDark}
          LeftIcon={IconArrowRight}
        />
      </div>

      <PromoBannerDecorationsDeprecated
        DecorIcon={DecorIcon}
        ellipseColorLeft={ellipseColorLeft}
        ellipseColorRight={ellipseColorRight}
      />
    </AnchorElement>
  );
});
